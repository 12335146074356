import React, { useState, useEffect } from 'react'
import Container from '../Container'

function DashboardAdmin(props) {


    return (
        <Container>
            <h1>Dashboardsdasd route </h1>
        </Container>
    )
}

export default DashboardAdmin