import React, { useState } from "react";
import { ModalContext } from "./modalContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Storage, API, graphqlOperation } from 'aws-amplify'
import { Scrollbars } from 'rc-scrollbars';


import config from '../../aws-exports'
import useFileHandlers from "./useFileHandlers";

const Component = (props) => {
    let { handleModal } = React.useContext(ModalContext);

    return (
        <>
            <button

                onClick={() => {
                    handleModal(<ContentComponent id={props.id} callback={props.callback} />);
                    props.callback(true);
                }}
            >
                <FontAwesomeIcon icon={props.icon} size="3x" className="mx-5" color={props.color} />
            </button>
        </>
    );
};

export default Component;


function ContentComponent(props) {




    const Input = (props) => (
        <input
            type="file"
            accept="video/mp4"
            name="img-loader-input"
            {...props}
        />
    )


    const {
        files,
        pending,
        next,
        uploading,
        uploaded,
        nouploaded,
        errors,
        status,
        onSubmit,
        onChange,
    } = useFileHandlers()




    return (
        <>

            {errors > 0 && (

                <div className="error">El Video no se pudo subir {errors}</div>
            )}

            <div className="container">
                <form className="form" onSubmit={onSubmit}>
                    {status === 'FILES_UPLOADED' && (

                        <div className="success-container">
                            <div>
                                <h2>Listo!</h2>
                                <small>Se ha subido el video</small>
                            </div>
                        </div>
                    )}
                    {uploading === true && (

                        <div className="success-container">
                            <div>
                                <h2>Espera ...</h2>
                                <small>Se esta subiendo el video</small>
                            </div>
                        </div>
                    )}
                    <div>
                        <Input onChange={onChange} id={props.id} bucket="video" />
                        <button type="submit">Submit</button>
                    </div>
                    <div>
                        <Scrollbars style={{ width: 500, height: 150 }}>
                            {files.map(({ file, src, id }, index) => (
                                <div
                                    style={{
                                        opacity: (uploaded[props.id] || nouploaded[props.id]) ? 0.2 : 1,
                                    }}
                                    key={`thumb${index}`}
                                    className="thumbnail-wrapper"
                                >

                                    <div className="thumbnail-caption">{file.name}</div>
                                    {status === 'UPLOAD_ERROR' && (
                                        <div className="error-ontainer">
                                            <div>
                                                <h2>Error</h2>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </Scrollbars>

                    </div>
                </form>
            </div>
        </>
    );
}
