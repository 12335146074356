import React from 'react';
import {  API, graphqlOperation} from 'aws-amplify';
import { createProperty } from '../graphql/mutations'
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import {
    Route,Redirect,
    useRouteMatch,
    useParams
} from "react-router-dom";

import { existsProperty } from '../graphql/queriescustom';


const initialState = {
    status: 'Inicial', video: 'false', photo: 'false', 
    inventario: 1, 
    pais: 'México'
}


function AddProperty(props) {

    let match = useRouteMatch();
  
    const { register, handleSubmit, watch, formState: { errors }, setError, reset } = useForm({
    defaultValues: {
      pais: "México",
      inventario: 1,
      status: "Inicial"
    }
  });



    const onSubmit = async data  => {
        console.log(data)
        try {
            const propertiesData = await API.graphql(graphqlOperation(existsProperty, {
              nombre: data.nombre,
            }))
            const total = propertiesData.data.searchPropertys.total
            if( total > 0){
            setError('nombre',{type:'server', message:'El nombre ya esta asignado a otra propiedad'} )
            return;
            }
          }
          catch (err) {
            console.log(err);
          }
       
        var lon ;
        var lat ;

        
        if(data.longitud ==parseInt(data.longitud,10))
            lon = parseFloat(data.longitud).toFixed(2)
        else
          lon = data.longitud;

        if(data.latitud ==parseInt(data.latitud,10))
          lat = parseFloat(data.latitud).toFixed(2)
       else
         lat = data.latitud;
   
        const amenidades = data.amenidades? data.amenidades.join(', '):""
        const propiedad  = {...initialState, nombre: data.nombre, tipo: data.tipo, propietario: data.propietario, descripcion: data.descripcion,
            caracteristicas: data.caracteristicas,precio: data.precio,
            recamaras: data.recamaras,estacionamientos: data.estacionamientos
        ,baths: data.baths, entidad: data.entidad
        ,wc: data.wc
        ,asesor: data.asesor
        ,construccion_m2: data.construccion_m2
        ,terreno_m2: data.terreno_m2
        ,localidad: data.localidad
        ,inventario: data.inventario
        ,direccion: data.direccion
        ,cp: data.cp
        ,edad: data.edad, amenidades: amenidades,   location:{
            lat: lat,
            lon: lon
          }
        }
     
        try{
           const res = await API.graphql(graphqlOperation(createProperty, { input: propiedad }))
           alert ("Propiedad Creada con Id :: " + res.data.createProperty.id);
           props.history.push("./publicado");
          // reset({keepDefaultValues:true,keepIsSubmitted:false})
        
         
        }
        catch (err) {
            if(err.errors){
                err.errors.map(d => ( d.message)).join()
                setError('nombre',{type:'server', message: err.errors.map(d => ( d.message)).join()} )          
            }
            else if (err.message){
                setError('nombre',{type:'server', message: err.message})   
            }
            else 
             setError('nombre',{type:'server', message:'Error de Conexión'}  )  
        }
        
    }
    return (
        <>            <div
                className=
                "relative flex  flex space-y-0  min-w-0  w-full mb-6 shadow-lg rounded bg-gray-600"
            >
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full ">
                            <h3
                                className=
                                "font-semibold text-lg text-gray-300"
                            >
                                Agrega nueva propiedad
              </h3>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>


                    <label htmlFor="nombre" >Nombre *
                    <input {...register("nombre", { required: 'error message'})}
                            placeholder="Nombre"
                        />
                    <div class="text-red-400 text-opacity-100 ">{errors.nombre?.type === 'required' && "Nombre es Requerido"}{errors.nombre?.type === 'server' && errors.nombre.message}</div>
                    </label>
                    <div class="flex space-x-3 ">
                    <label htmlFor="tipo">Tipo de Inmueble*
                    
                    <select class="m-1 px-2 py-1 rounded-full" {...register("tipo", { required: true })}

                            placeholder="Tipo Inmueble"
                        >
                            <option value="Casa">Casa</option>
                            <option value="Departamento">Departamento</option>
                            <option value="Terreno">Terreno</option>
                            <option value="Oficina">Oficina</option>
                        </select>
                    </label>
                    </div>
                    <div class="flex space-x-3">
                    <label htmlFor="propietario">Propietario *
                    <input type="email" {...register("propietario", { required: true })}
                            placeholder="Email Propietario(User Id)"
                        />
                    <div class="text-red-400 text-opacity-100 ">{errors.propietario?.type === 'required' && "Propietario es Requerido"}</div>

                    </label>

                    <label htmlFor="asesor">Asesor *
                    <input type="email" {...register("asesor", { required: true })}
                            placeholder="contacto@safesale.com"
                        />
                    <div class="text-red-400 text-opacity-100 ">{errors.asesor?.type === 'required' && "Asesor es Requerido"}</div>

                    </label>
                    </div>
                    <div class="flex space-x-3">
                    <label htmlFor="latitud" >Latitud *
                    <input type="number" step="any" {...register("latitud", { required: true, min: 10, max: 50, pattern:"[+-]?\d+(?:[.,]\d+)?"})}
                            placeholder="Latitud"
                        />
                    <div class="text-red-400 text-opacity-100 ">{errors.latitud?.type === 'required' && "Latitud es Requerido"}{errors.latitud != null && errors.latitud?.type !== 'required' && "Rango de 10 a 50"}</div>

                    </label>

                    <label htmlFor="longitud">Longitud *
                    <input type="number" step="any" {...register("longitud", { required: true, min: -130, max: -30 })}
                            placeholder="Longitud"
                        />
                    <div class="text-red-400 text-opacity-100 ">{errors.longitud?.type === 'required' && "Longitud es Requerido"}{errors.longitud != null && errors.longitud?.type !== 'required' && "Rango de -130 a -30"}</div>

                    </label>
                    </div>

                    <label htmlFor="descripcion">Descripción *
                    <input {...register("descripcion", { required: true })}
                            placeholder="Descripción"
                        />
                    <div class="text-red-400 text-opacity-100 ">{errors.descripcion?.type === 'required' && "Descripcion es Requerido"}</div>

                    </label>

                    <label htmlFor="caracteristicas">Características *
                    <input {...register("caracteristicas", { required: true })}
                            placeholder="Características"
                        />
                    <div class="text-red-400 text-opacity-100 ">{errors.caracteristicas?.type === 'required' && "Caracteristicas es Requerido"}</div>

                    </label>

                     
                    <label htmlFor="amenidades">Amenidades*</label>
                    <div class="flex space-x-3">
                    {["Gimnasio", "Ludoteca", "Salón de Usos Múltiples", "Alberca", "Jardín", "Roof Garden"].map((value) => (
                        <label> {value}
                            <input
                                key={value}
                                type="checkbox"
                                value={value}
                                text={value}
                                {...register("amenidades", {

                                })}
                            />
                            
                        </label>
                    ))}
                     </div>
                   


                 

                    <div class="flex space-x-3">
                        <label  htmlFor="recamaras">Recámaras*
                        <select class="p-4 px-2 py-1 rounded-full" {...register("recamaras", { required: true }) }
                                placeholder="Recámaras"
                            >
                                <option value="0" selected="true"> 0 </option>
                                <option value="1"> 1 </option>
                                <option value="2"> 2 </option>
                                <option value="3"> 3 </option>
                                <option value="4"> 4 </option>
                                <option value="5"> 5 </option>
                                <option value="6"> 6 </option>
                                <option value="7"> 7 </option>
                                <option value="7"> 8 </option>
                                <option value="7"> 9 </option>
                                <option value="7"> 10 </option>
                            </select>
                        </label>

                        <label htmlFor="estacionamientos">Estacionamientos
                        <select class="px-2 py-1 rounded-full" {...register("estacionamientos", { required: true })}
                                placeholder="Estacionamientos"
                            >
                                <option value="0" selected="true"> 0 </option>
                                <option value="1"> 1 </option>
                                <option value="2"> 2 </option>
                                <option value="3"> 3 </option>
                                <option value="4"> 4 </option>
                                <option value="5"> 5 </option>
                                <option value="6"> 6 </option>
                                <option value="7"> 7 </option>
                                <option value="7"> 8 </option>
                                <option value="7"> 9 </option>
                                <option value="7"> 10 </option>
                            </select>
                        </label>
                        <label htmlFor="baths">Baños Completos
                        <select  class="px-2 py-1 rounded-full" {...register("baths", { required: true })}
                                placeholder="Baños"
                            >
                                <option value="0" selected="true"> 0 </option>
                                <option value="1"> 1 </option>
                                <option value="2"> 2 </option>
                                <option value="3"> 3 </option>
                                <option value="4"> 4 </option>
                                <option value="5"> 5 </option>
                                <option value="6"> 6 </option>
                                <option value="7"> 7 </option>
                                <option value="7"> 8 </option>
                                <option value="7"> 9 </option>
                                <option value="7"> 10 </option>
                            </select>
                        </label>
                        <label htmlFor="wc">Medios baños
                        <select class="px-2 py-1 rounded-full" {...register("wc", { required: true })}
                                placeholder="Medios baños"
                            >
                                <option value="0" selected="true"> 0 </option>
                                <option value="1"> 1 </option>
                                <option value="2"> 2 </option>
                                <option value="3"> 3 </option>
                                <option value="4"> 4 </option>
                                <option value="5"> 5 </option>
                                <option value="6"> 6 </option>
                                <option value="7"> 7 </option>
                                <option value="7"> 8 </option>
                                <option value="7"> 9 </option>
                                <option value="7"> 10 </option>
                            </select>
                        </label>
                    </div>
                    <div class="flex space-x-3">
                       <label htmlFor="precio">Precio *
                    <input type = "number" step="any" {...register("precio", { required: true })}
                            placeholder="Precio"
                        />
                    <div class="text-red-400 text-opacity-100 ">{errors.precio?.type === 'required' && "Precio es Requerido"}</div>

                    </label>
                        <label htmlFor="terreno_m2">Terreno (m2) *
                        <input type="number" step="any" {...register("terreno_m2", { required: true })}
                                placeholder="Númerico en metros cúbicos"
                            />
                            <div class="text-red-400 text-opacity-100 ">{errors.terreno_m2?.type === 'required' && "Metros es Requerido"}</div>

                        </label>
                        <label htmlFor="construccion_m2">Construcción (m2) *
                        <input type="number" step="any" {...register("construccion_m2", { required: true })}
                                placeholder="Númerico en metros cúbicos"
                            />
                            <div class="text-red-400 text-opacity-100 ">{errors.construccion_m2?.type === 'required' && " es Requerido"}</div>

                        </label>
                    </div>

                    <div class="flex space-x-2">    
                        <label htmlFor="inventario">Inventario
                        <input type="number" {...register("inventario", { required: true, min: 1, max: 99})}
                                placeholder="Número de unidades disponibles"
                            />
                        <div class="text-red-400 text-opacity-100 ">{errors.inventario?.type === 'required' && "Invetario es Requerido"}{errors.inventario != null && errors.inventario?.type !== 'required' && "Valores de 1 a 99"}</div>

                        </label>

                        <label htmlFor="edad">Antigüedad
                        <input type="number" {...register("edad", { required: true, min: 0, max: 99})}
                                placeholder="Años de edad de la propiedad"
                            />
                        <div class="text-red-400 text-opacity-100 ">{errors.edad?.type === 'required' && "Valores de 0 a 99"}</div>

                        </label>

                    </div>

                    <label htmlFor="direccion">Direccion
                    <input {...register("direccion", { required: true})}
                            placeholder="Calle Número Exterior, Interior y Colonia"
                        />
                    <div class="text-red-400 text-opacity-100 ">{errors.direccion?.type === 'required' && "Direccion es Requerido"}</div>

                    </label>
                <div class="flex space-x-3">
                    <label htmlFor="localidad">Localidad
                    <input {...register("localidad", { required: true })}
                            placeholder="Delegación o Municipio"
                        />
                    <div class="text-red-400 text-opacity-100 ">{errors.localidad?.type === 'required' && "Localidad es Requerido"}</div>

                    </label>

               
                    
                    <label htmlFor="cp">Código Postal
                    <input type= "number" {...register("cp", { required: true})}
                            placeholder="Código Postal"
                        />
                    <div class="text-red-400 text-opacity-100 ">{errors.cp?.type === 'required' && "CP es Requerido"}</div>

                    </label>
                    

                    <label htmlFor="entidad">Entidad Federativa
                    <select class="px-2 py-1 rounded-full" {...register("entidad", { required: true })}
                            placeholder="Entidad"
                        >

                        <option value="Aguascalientes">Aguascalientes</option>
                        <option value="Baja California">Baja California</option>
                        <option value="Baja California Sur">Baja California Sur</option>
                        <option value="Campeche">Campeche</option>
                        <option value="Coahuila de Zaragoza">Coahuila de Zaragoza</option>
                        <option value="Colima">Colima</option>
                        <option value="Chiapas">Chiapas</option>
                        <option value="Chihuahua">Chihuahua</option>
                        <option value="Ciudad de México">Ciudad de México</option>
                        <option value="Durango">Durango</option>
                        <option value="Guanajuato">Guanajuato</option>
                        <option value="Guerrero">Guerrero</option>
                        <option value="Hidalgo">Hidalgo</option>
                        <option value="Jalisco">Jalisco</option>
                        <option value="Estado de México" selected ="true">Estado de México</option>
                        <option value="Michoacán de Ocampo">Michoacán de Ocampo</option>
                        <option value="Morelos">Morelos</option>
                        <option value="Nayarit">Nayarit</option>
                        <option value="Nuevo León">Nuevo León</option>
                        <option value="Oaxaca">Oaxaca</option>
                        <option value="Puebla">Puebla</option>
                        <option value="Querétaro">Querétaro</option>
                        <option value="Quintana Roo">Quintana Roo</option>
                        <option value="San Luis Potosí">San Luis Potosí</option>
                        <option value="Sinaloa">Sinaloa</option>
                        <option value="Sonora">Sonora</option>
                        <option value="Tabasco">Tabasco</option>
                        <option value="Tamaulipas">Tamaulipas</option>
                        <option value="Tlaxcala">Tlaxcala</option>
                        <option value="Veracruz">Veracruz</option>
                        <option value="Yucatán">Yucatán</option>
                        <option value="Zacatecas">Zacatecas</option>
                    </select>
                </label>
                </div>
                <input  hidden="true" {...register("pais", { required: true })}
                      
                />
              

                <input hidden="true" {...register("status", { required: true  })}
                       
                />
              
              
        
                    <input type="submit" />


                </form>
            </div>
        </>
    )
}

const styles = {
    container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
    todo: { marginBottom: 15 },
    input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 14 },
    propertyName: { fontSize: 20, fontWeight: 'bold' },
    propertyDescripcion: { marginBottom: 0 },
    button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

export default withRouter(AddProperty)