import React from "react";
import { ModalContext } from "./modalContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Scrollbars } from 'rc-scrollbars';



import useFileHandlers from "./useFileHandlers";




const ImageComponent = (props) => {
    let { handleModal } = React.useContext(ModalContext);

    return (
        <>
            <button

                onClick={() => { handleModal(<ContentComponent id={props.id} />); props.callback(true) }}
            >
                <FontAwesomeIcon icon={props.icon} size="3x" className="mx-5" color={props.color} />
            </button>
        </>
    );
};

export default ImageComponent;


function ContentComponent({ id }) {

    const Input = (props) => (
        <input
            type="file"
            accept="image/*"
            name="img-loader-input"
            multiple
            {...props}
        />
    )


    const {
        files,
        uploaded,
        nouploaded,
        errors,
        status,
        onSubmit,
        onChange,
    } = useFileHandlers()

    return (
        <>

            {errors > 0 && (

                <div className="error">Archivos sin subir: {errors}</div>
            )}

            <div className="container">
                <form className="form" onSubmit={onSubmit}>
                    {status === 'FILES_UPLOADED' && (

                        <div className="success-container">
                            <div>
                                <h2>Listo!</h2>
                                <small>Se han subido las fotos</small>
                            </div>
                        </div>
                    )}
                    <div>
                        <Input onChange={onChange} id={id} bucket="images" />
                        <button type="submit">Submit</button>
                    </div>
                    <div>
                        <Scrollbars style={{ width: 500, height: 300 }}>
                            {files.map(({ file, src, id }, index) => (
                                <div
                                    style={{
                                        opacity: (uploaded[id] || nouploaded[id]) ? 0.2 : 1,
                                    }}
                                    key={`thumb${index}`}
                                    className="thumbnail-wrapper"
                                >
                                    <img className="thumbnail" src={src} alt="" />
                                    <div className="thumbnail-caption">{file.name}</div>
                                    {status === 'UPLOAD_ERROR' && (
                                        <div className="error-ontainer">
                                            <div>
                                                <h2>Error</h2>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </Scrollbars>

                    </div>
                </form>
            </div>
        </>
    );
}
