import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Menu } from 'antd'

import { HomeOutlined, ProfileOutlined } from
  '@ant-design/icons'


// Menu Header

import Public from './Public'
import Profile from './Profile'
import Protected from './Protected'
import Admin from './Admin'
import Politica from './Politicas';

export default function App() {

  const [userType, setUserType] = useState('guest')
  const current = 'home'

  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
    });
    const asyncCallback = async () => {
      await Auth.currentSession().then(data => {
        const groups = data.getIdToken().payload["cognito:groups"];
        if (groups.indexOf("UserGroupSaleForce") !== - 1)
          setUserType('admin');
        else if (groups.indexOf("UserGroupCustomers") !== -1)
          setUserType('customer')
        else
          setUserType('user')
        return;
      }).catch(err => {
        setUserType('guest');
        console.log("ERR ::" + userType);
        return;
      })
    }
    asyncCallback()
  }
  )
  return (
    <Router>
      <div>
        <Menu selectedKeys={[current]} mode="horizontal">
          <Menu.Item key='home'>
            <Link to={`/`}>
              <HomeOutlined />Home
            </Link>
          </Menu.Item>
          <Menu.Item key='pliticadeprivacidad'>
            <Link to={`/politicadeprivacidad`}>
              <HomeOutlined />Políticas de Privacidad
            </Link>
          </Menu.Item>
          <Menu.Item key='profile'>
            <Link to='/profile'>
              <ProfileOutlined />Profile
            </Link>
          </Menu.Item>
          {
            userType === 'admin' && <Menu.Item key='admin'>
              <Link to='/admin'>
                <ProfileOutlined />Dashboard de Ventas
              </Link>
            </Menu.Item>
          }


        </Menu>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/"
            render={props => (<Public {...props} userType={userType} />)} />
          <Route exact path="/politicadeprivacidad"
            render={props => (<Politica {...props} userType={userType} />)} />
          {
            userType === 'admin' && <Route path="/admin"
              render={props => (<Admin {...props} userType={userType} />)} />
          }
          <Route exact path="/protected"
            render={props => (<Protected {...props} userType={userType} />)} />
          <Route exact path="/profile"
            render={props => (<Profile {...props} userType={userType} />)} />
        </Switch>
      </div>
    </Router>
  );
}


