/* src/index.js */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import "./tailwind.css";
import App from './App';

import Amplify from 'aws-amplify'
import config from './aws-exports'
Amplify.configure(config)





ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
