export const propertiesInicialStatus = /* GraphQL */ `
  query TotalInitialStatus ($status: String!){
    searchPropertys(filter: {status: {eq: $status}}) {  total
  }
  }
`;


export const existsProperty = /* GraphQL */ `
query existsProperty( $nombre: String ) {
  searchPropertys( filter: { nombre: {eq: $nombre}}) {
    total
  }
}`;

export const getPropertiesOrderByCreatedAt = /* GraphQL */`
query getPropertiesOrderByCreatedAt($status: String, $nextToken: String, $limit:Int) {
    searchPropertys(
      nextToken:$nextToken,
      filter:{status: {eq: $status}},
      sort: {field: createdAt, direction: desc}, limit: $limit) {
      nextToken
      total
      items {
        amenidades
        asesor
        baths
        caracteristicas
        construccion_m2
        cp
        createdAt
        descripcion
        direccion
        edad
        entidad
        estacionamientos
        id
        inventario
        localidad
        nombre
        location {
          lat
          lon
        }
        pais
        photo
        precio
        propietario
        recamaras
        status
        terreno_m2
        tipo
        updatedAt
        video
        wc
      }
    }
  }
  `;

