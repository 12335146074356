/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:16993369-fa6e-4491-9cb2-a8dcbb116f4d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_THHxX0XMz",
    "aws_user_pools_web_client_id": "21mvqiok699brfqb0kgqs7fs8p",
    "oauth": {},
    "aws_user_files_s3_bucket": "s3-bucket-properties205233-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://m5rpvgvc5rh6dlwbqlrxg5s3fi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_mobile_analytics_app_id": "7fdc9f9916334dd9ab7404dc658f760a",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
