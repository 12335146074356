import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify'
import Container from './Container'

function Protected(props) {
    const [userType, setUserType] = useState()
    useEffect(() => {
        const asyncCallback = async () => {
            Auth.currentAuthenticatedUser()
                .catch(() => {
                    props.history.push('/profile')
                })
            setUserType(props.userType)
        }
        asyncCallback()

    }, [props])



    return (
        <Container>
            <h1>Protected route {userType}</h1>
        </Container>
    );
}

export default Protected


