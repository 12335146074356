import React, { useState, useEffect } from 'react'
import Container from '../Container'
import CardTable from "../components/Cards/CardTable.js";


function Inicial(props) {



    return (<>
        <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 px-4">
                <CardTable status='Inicial' />
            </div>
        </div>
    </>);


}

export default Inicial
