import React, { useState, useEffect } from 'react'
import CardTable from "../components/Cards/CardTable.js";

function Published(props) {

    return (<>
        <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 px-4">
                <CardTable status='Publicado"' />
            </div>
        </div>
    </>
    )
}

export default Published