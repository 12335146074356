import { useCallback, useEffect, useReducer, useRef } from 'react'
import { Storage, API, graphqlOperation } from 'aws-amplify'
import '../../App.css'
import { updateProperty } from '../../graphql/mutations'

import config from '../../aws-exports'
import config2 from '../../aws-video-exports'

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

const {
    awsInputVideo: bucket_input
} = config2



const api = {


    async uploadFile(next, id, bucketname) {
        let key
        let inputData
        let namebucket

        let extension = next.file.name.substring(next.file.name.lastIndexOf(".") + 1, next.file.name.length)
        if (extension === "jpeg") extension = "jpg"
        const { type: mimeType } = next.file

        if (bucketname === "images") {
            key = `images/${id}/${id}${next.id}.${extension}`
            inputData = { id: id, galery: { bucket: `${bucket}`, key: id, region: region }, photo: true }
            namebucket = bucket
        }
        else {

            key = `${id}.${extension}`
            inputData = { id: id, video: true }
            namebucket = bucket_input
        }



        try {
            await Storage.put(key, next.file, {
                bucket: namebucket,
                contentType: mimeType,
                progressCallback(progress) {
                    console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
                }


                // contentType is optional
            });
            if (next.id === 0)
                await API.graphql(graphqlOperation(updateProperty, { input: inputData }))

        } catch (err) {
            //    console.log('Error uploading file:', err);
            throw err;
        }
    },
}

const logUploadedFile = (num, color = 'green') => {
    const msg = `%cUploaded ${num} files.`
    const style = `color:${color};font-weight:bold;`
    console.log(msg, style)
}

const logErrorFile = (error, color = 'red') => {
    const msg = `%cNot uploaded curren file.`
    const style = `color:${color};font-weight:bold;`
    console.log(msg, style)
}

// Constants
const LOADED = 'LOADED'
const INIT = 'INIT'
const PENDING = 'PENDING'
const FILES_UPLOADED = 'FILES_UPLOADED'
const UPLOAD_ERROR = 'UPLOAD_ERROR'

const initialState = {
    files: [],
    id: "",
    bucketname: "",
    pending: [],
    next: null,
    uploading: false,
    uploaded: {},
    errors: 0,
    nouploaded: {},
    status: 'idle',
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'load':
            return { ...state, files: action.files, id: action.id, bucketname: action.bucketname, status: LOADED }
        case 'submit':
            return { ...state, uploading: true, pending: state.files, status: INIT }
        case 'next':
            return {
                ...state,
                next: action.next,
                status: PENDING,
            }
        case 'file-uploaded':
            return {
                ...state,
                next: null,
                pending: action.pending,
                uploaded: {
                    ...state.uploaded,
                    [action.prev.id]: action.prev.file,
                },
            }
        case 'files-uploaded':
            return { ...state, uploading: false, status: FILES_UPLOADED }
        case 'set-upload-error':
            return {
                ...state, next: null,
                pending: action.pending,
                nouploaded: {
                    ...state.nouploaded,
                    [action.prev.id]: action.prev.file,
                }, uploadError: action.error, status: UPLOAD_ERROR, errors: action.errors
            }
        default:
            return state
    }
}

const useFileHandlers = () => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const onSubmit = useCallback(
        (e) => {

            e.preventDefault()
            if (state.files.length) {

                dispatch({ type: 'submit' })
            } else {
                window.alert("You don't have any files loaded.")
            }
        },
        [state.files.length],
    )

    const onChange = (e) => {
        if (e.target.files.length) {
            const arrFiles = Array.from(e.target.files)
            const files = arrFiles.map((file, index) => {
                const src = window.URL.createObjectURL(file)
                return { file, id: index, src }
            })
            dispatch({ type: 'load', files, id: e.target.id, bucketname: e.target.attributes['bucket'].value })
        }
    }

    // Sets the next file when it detects that its ready to go
    useEffect(() => {
        if (state.pending.length && state.next == null) {
            const next = state.pending[0]
            dispatch({ type: 'next', next })
        }
    }, [state.next, state.pending])

    const countRef = useRef(0)

    // Processes the next pending thumbnail when ready
    useEffect(() => {
        if (state.pending.length && state.next) {
            const { next } = state

            const { id } = state


            api
                .uploadFile(next, id, state.bucketname)
                .then(() => {
                    const prev = next
                    logUploadedFile(++countRef.current)
                    const pending = state.pending.slice(1)
                    dispatch({ type: 'file-uploaded', prev, pending })
                })
                .catch((error) => {
                    const errors = state.errors + 1
                    const prev = next
                    logErrorFile(error)
                    const pending = state.pending.slice(1)
                    dispatch({ type: 'set-upload-error', error, prev, pending, errors })
                })
        }
    }, [state])

    // Ends the upload process
    useEffect(() => {
        if (!state.pending.length && state.uploading) {
            dispatch({ type: 'files-uploaded' })
        }
    }, [state.pending.length, state.uploading])

    return {
        ...state,
        onSubmit,
        onChange,
    }
}

export default useFileHandlers