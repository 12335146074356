import {
    Route,
    useRouteMatch,
    useParams
} from "react-router-dom";


import AddProperty from './admin/AddProperty'
import Inicial from './admin/Inicial'
import DashboardAdmin from './admin/DashboardAdmin'
import Published from './admin/Published'

import HeaderStats from './components/Headers/HeaderStats.js';




function Admin(props) {

    let match = useRouteMatch();


    return (
        <>
            <div className=" w-full  bg-gray-200">
                <HeaderStats />


                <div className="px-4 md:px-10 mx-auto w-full -m-24">


                    <Route exact path={`${match.path}/inicial`}>
                        <Inicial />
                    </Route>
                    <Route exact path={`${match.path}/publicado`}>
                        <Published />
                    </Route>
                    <Route exact path={`${match.path}/addproperty`}>
                        <AddProperty />
                    </Route>
                    <Route exact path={`${match.path}/dashboard`}>
                        <DashboardAdmin />
                    </Route>
                    <Route exact path={`${match.path}`}>
                        <DashboardAdmin />
                    </Route>

                </div>

            </div>
        </>
    )
}


export default Admin