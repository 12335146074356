import React, { useState, useEffect, useRef } from 'react'
import PropTypes from "prop-types";
import axios from 'axios';
import { API, graphqlOperation } from 'aws-amplify';
import { getPropertiesOrderByCreatedAt } from '../../graphql/queriescustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileVideo, faFileImage, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import { ModalProvider } from '../Modals/modalContext'
import Component from "../Modals/component";
import ImageComponent from '../Modals/imagescomponent';









// components



function CardTable({ color, status }, props) {


  const [properties, setProperties] = useState([])


  const [reload, setReload] = useState(false);


  useEffect(() => {
    fetchProperties()
  }, [props.userType])

  async function fetchProperties() {
    try {
      const propertiesData = await API.graphql(graphqlOperation(getPropertiesOrderByCreatedAt, {
        status: "Inicial",
        limit: 500,
        nextToken: null
      }))
      console.log("Si entra al get");
      const properties = propertiesData.data.searchPropertys.items
      setProperties(properties)
    }
    catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-blue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-gray-800" : "text-white")
                }
              >
                {
                  (status === "Inicial" ? "Propiedades Pendientes de Multimedia" : "Propiedades Publicadas")
                }
              </h3>
            </div>
          </div>
        </div>


        <div className="block w-full overflow-x-auto">


          {/* Properties table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  ID
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Propietario
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Nombre
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Direccion
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Tipo
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Precio
                </th>

                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Fecha de Creacion
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody>
              {
                properties.map((property) => (
                  <tr key={property.id}>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left flex items-center" >
                      <span
                        className={
                          "ml-3 font-bold " +
                          +(color === "light" ? "text-gray-700" : "text-white")
                        }
                      >
                        {property.id}
                      </span>
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                      {property.propietario}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                      {property.nombre}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                      {property.direccion}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                      <i className="fas fa-circle text-blue-500 mr-2"></i>{property.tipo}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                      <i className="fas fa-circle text-blue-500 mr-2"></i>{property.precio}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                      {property.createdAt}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4">
                      <div className="flex">

                        <ModalProvider >
                          <Component callback={setReload} icon={faFileVideo} id={property.id} color={property.video ? "green" : "red"} />
                        </ModalProvider>
                        <ModalProvider >
                          <ImageComponent callback={setReload} icon={faFileImage} id={property.id} color={property.photo ? "green" : "red"} />
                        </ModalProvider>

                      </div>

                    </td>
                  </tr>

                ))
              }
            </tbody>
          </table>
        </div>

      </div >
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};


export default CardTable;