import React, { useState, useEffect } from 'react'
import Container from './Container'

function Public(props) {
    const [userType, setUserType] = useState()
    useEffect(() => {
        setUserType(props.userType)
    }, [props.userType])

    return (
        <Container>
            <h1>Public route {userType}</h1>
        </Container>
    )
}

export default Public