

import React, { useState, useEffect } from 'react'
import CardStatus from '../Cards/CardStatus.js'
import { API, graphqlOperation } from 'aws-amplify';

import { propertiesInicialStatus } from '../../graphql/queriescustom'

import {
  useRouteMatch, Link
} from "react-router-dom";


// components



export default function HeaderStats() {
  let match = useRouteMatch();
  const [totales, setTotales] = useState({ Inicial: 0, Publicado: 0, Expirado: 0 })

  useEffect(() => {
    getTotalInitialProperties()
  })


  async function getTotalInitialProperties() {
    try {
      const totalI = await API.graphql(graphqlOperation(propertiesInicialStatus, { status: "Inicial" }))
      const totalP = await API.graphql(graphqlOperation(propertiesInicialStatus, { status: "Publicado" }))
      const totalE = await API.graphql(graphqlOperation(propertiesInicialStatus, { status: "Expirado" }))
      let temp = { ...totales }
      if (totalI.data.searchPropertys.total)
        temp.Inicial = await totalI.data.searchPropertys.total;
      if (totalP.data.searchPropertys.total)
        temp.Publicado = await totalP.data.searchPropertys.total;
      if (totalE.data.searchPropertys.total)
        temp.Expirado = await totalE.data.searchPropertys.total;
      setTotales(temp)
    }
    catch (err) {
      console.log(err);
    }
  }





  return (
    <>
      {/* Header */}
      <div className="relative bg-blue-300 md:pt-32 pb-32 pt-12">
        <div className="px4 md:px-40 mx-auto min-w-full">

          {/* Card stats */}
          <div className="flex flex-wrap justify-end">
            <div className="w-full lg:w-6/12 xl:w-2/12 px-4">
              <Link to={`${match.url}/inicial`}>
                <CardStatus
                  statSubtitle="PROPIEDADES PENDIENTES "
                  statTitle={totales.Inicial.toString()}
                  statIconName="fas fa-chart-pie"
                  statDescripiron="Agrega video, y fotos"
                  statIconColor="bg-green-500"
                /> </Link>
            </div>
            <div className="w-full lg:w-6/12 xl:w-2/12 px-4">
              <Link to={`${match.url}/publicado`}>
                <CardStatus
                  statSubtitle="PROPIEDADES PUBLICADAS"
                  statTitle={totales.Publicado.toString()}
                  statDescripiron="Actualiza datos"
                  statIconName="fas fa-chart-pie"
                  statIconColor="bg-orange-500"
                />
              </Link>
            </div>
            <div className="w-full lg:w-6/12 xl:w-2/12 px-4">
              <Link to={`${match.url}/addproperty`}>
                <CardStatus
                  statSubtitle="Agregar Propiedad"
                  statTitle="(+)"
                  statDescripiron="Ingresa una nueve propiedad"
                  statIconName="fas fa-percent"
                  statIconColor="bg-blue-500"
                />
              </Link>
            </div>
          </div>

        </div>
      </div>
    </>
  );


}
