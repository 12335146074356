import React, { useState, useEffect } from 'react'
import Container from './Container'
import HeaderStats from './components/Headers/HeaderStats'

function Politica(props) {
    const [userType, setUserType] = useState()
    useEffect(() => {
        setUserType(props.userType)
    }, [props.userType])

    return (
        <>
            <div className={
                "h-screen relative flex flex-col min-w-0 break-words w-full h-full mb-6 shadow-lg rounded " +
                ("bg-white text-black")
            }>

                <div className="rounded-t mb-0 px-4 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h1
                                className={
                                    "font-semibold text-lg " +
                                    ("text-gray-800")
                                }
                            >Políticas de Privacidad
                            </h1>
                        </div>
                    </div>
                </div>


                <div className="px4 md:px-40 mx-auto min-w-full">

                    <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg"  >
                        <div className="flex-auto p-4">
                            <div className="flex flex-wrap">

                            </div>
                            <p className="text-sm text-gray-500 mt-4">
                                <span className="whitespace-no-wrap">
                                    Safe Sale SA de CV construyó la aplicación Safe Sale como una aplicación sin costo. Este SERVICIO es ofrecido por  Safe Sale SA de CV
                                    sin costo.
                                </span>
                            </p>
                            <p className="text-sm text-gray-500 mt-4">
                                Esta página es usada para informar a los usuarios sobre nuestras políticas de uso de datos personales.
                            </p> <p className="text-sm text-gray-500 mt-4">
                                Si tú eliges usar nuestro servicio, estás de acuerdo a que colectemos y usemos tu información acorde a esta política. La información personal que nosotros
                                resguardamos es usada para proveer y mejor el servicio. Nosotros no usaremos o compartiremos tu información con nadie excepto con los participantes descritos en esta Política.
                            </p> <p className="text-sm text-gray-500 mt-4">
                                Los terminos usados in esta Política de Privacidad son iguales a los Términos y Condiciones en nuestra página corporativa.
                            </p> <p className="text-sm text-gray-500 mt-4"><strong>Recolección de datos y uso</strong></p> <p className="text-sm text-gray-500 mt-4">
                                Para hacer una mejor experiencia, mientras usas nuestros servicios, nosotros requerimos que nos compartas cierta información de identidad, incluída
                                pero no limitada a :
                                Correo electrónico (Usado como id)
                                Nombre (Usado para poder enviarte información personalizada)
                                ID de dispositivo (Usado para poder enviarte notificaciones)
                            </p> <p className="text-sm text-gray-500 mt-4"><strong>Información de Error</strong></p> <p className="text-sm text-gray-500 mt-4">
                                Queremos informarte que en caso de error en la aplicación, nosotros podemos guardar información tal como IP, nombre del dispositivo, sistema operativo  y la fecha y hora en que usaste el servicio, con el
                                fin de poder identificar la causa raíz del problema.
                            </p>  <p className="text-sm text-gray-500 mt-4"><strong>Política para Niños</strong></p> <p className="text-sm text-gray-500 mt-4">
                                Este servicio no esta dirigido a menores de edad.
                            </p> <p className="text-sm text-gray-500 mt-4"><strong>Cambios a estra Política de Privaciada</strong></p> <p className="text-sm text-gray-500 mt-4">
                                Nosotros podemos actualizar nuestra política en cualquier momento. Por eso te invitamos a que revises esta página periódicamente, por cualquier cambio.
                                Nosotros te notificaremos cualquier cambio actualizando esta página.
                            </p> <p className="text-sm text-gray-500 mt-4">Esta política es efectiva desde  2021-06-01</p> <p className="text-sm text-gray-500 mt-4"><strong>Contáctanos</strong></p> <p className="text-sm text-gray-500 mt-4">
                                Si tienes cualquier duda o sugerencia acerca de la política de privacidad contactos  contactsafesale@gmail.com.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Politica